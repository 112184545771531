<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.users">
			<template #title>Users</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'UsersNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>

			<v-data-table :headers="headers" :items="users" item-key="name" @click:row="editItem" :item-class="() => 'hover-pointer'" :items-per-page="50">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
								@input="getUsers(search)"
							></v-text-field>
						</v-col>
					</v-row>
				</template>
				<template v-slot:footer.prepend>
					<v-row>
						<v-col cols="auto">
							<ti-export-to-csv :json-data="users" :filename="`users`"/>
						</v-col>
					</v-row>
				</template>
				<template v-slot:item.is_active="{ item }">
					<v-icon v-if="item.is_active" color="success">mdi-check</v-icon>
					<v-icon v-else color="error">mdi-close</v-icon>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "UsersIndex",
	computed: {
		users: sync('users/users'),
	},
	data: () => ({
		loading: false,
		pagination: {},
		search: '',
		headers: [
			{
				text: 'First name',
				align: 'start',
				sortable: true,
				value: 'first_name',
			},
			{
				text: 'Last name',
				align: 'start',
				sortable: true,
				value: 'last_name',
			},
			{
				text: 'Email',
				align: 'start',
				sortable: true,
				value: 'email',
			},
			{
				text: 'Membership status',
				align: 'start',
				sortable: true,
				value: 'membership_status',
			},
			{
				text: 'User Type',
				align: 'start',
				sortable: true,
				value: 'type',
			},
			{
				text: 'Stripe ID',
				align: 'start',
				sortable: true,
				value: 'stripe_customer_id',
			},
		]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.getUsers()
		},
		editItem(data) {
			this.$router.push({name: 'UsersEdit', params: {id: data.id}})
		},
		getUsers(searchTerm = '', page = 1) {
			console.log('searchTerm', searchTerm)
			this.loading = true;
			let urlEncodedSearchTerm = encodeURIComponent(searchTerm)
			this.$api.users.index(page, urlEncodedSearchTerm)
				.then(response => {
					this.users = response.data.users;
					this.pagination = response.data.pagination;
				})
				.catch(error => {
					this.$toast.add(error.message, 'error')
				})
				.finally(() => {
					this.loading = false;
				})
		},
		filterOnlyCapsText(value, search, item) {
			return value != null &&
				search != null &&
				typeof value === 'string' &&
				value.toString().toLocaleUpperCase().indexOf(search) !== -1
		},
	}
}
</script>